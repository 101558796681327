// Home.js

import PandaBuySignUpLink from "../PandaBuySignUpLink/PandaBuySignUpLink";
import Hero from "../Home/hero";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/navigation";
import FeaturedItems from "./FeaturedItems";
import React from "react";
import Faq from "../Faq/Faq";

const Home = () => {
  return (
    <div>
      <PandaBuySignUpLink />
      <Navigation />
      <Hero />
      <Footer />
    </div>
  );
};

export default Home;
