import {  useState } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faBarsStaggered, faClose, faShop } from '@fortawesome/free-solid-svg-icons';
import { PageTitle } from "../../config/config";
export default function Navigation() {

  const [sideMenu, setSideMenu] = useState(false);
  return (
    <nav id={"navigation"} className={"sticky-nav flex h-[100px] container mx-auto items-center justify-between max-sm:p-8"}>
      <Link to="/" className="flex items-center gap-x-2">
      <FontAwesomeIcon style={{color: 'var(--text-header-color)'}} icon={faShop} />
            <h1 className="navbrandtxt uppercase mr-4 tracking-wide">{PageTitle}</h1>
      </Link>

      <ul className={`${sideMenu ? "flex text-4xl flex-col gap-y-4" : "hidden md:flex gap-x-4"} flex-col p-8 md:p-0 w-full h-full md:w-auto md:h-auto fixed md:relative top-0 left-0 backdrop-blur-sm bg-[rgba(0,0,0,0.8)] md:bg-transparent justify-center md:flex-row items-start md:items-center z-[100]`}>
      <li className="navbrandtxt2 hover:underline"><Link to="/products">Products</Link></li>
        <li className="navbrandtxt2 hover:underline"><Link to="/guide">Guide</Link></li>
        <li className="navbrandtxt2 hover:underline"><Link to="/watchlist">Watchlist</Link></li>
        <Link to="/contact" className="navbrandtxt2 md:border md:rounded-xl md:border-[] md:w-[125px] md:text-center md:p-[0.35rem]" style={{borderColor: 'var(--call-to-action-color)'}} ><p>Contact <FontAwesomeIcon icon={faAddressCard} className="max-sm:hidden md:pl-2" /></p></Link>
      </ul>

      <FontAwesomeIcon
        onClick={() => setSideMenu(!sideMenu)}
        icon={sideMenu ? faClose : faBarsStaggered}
        className=" cursor-pointer z-[100] w-[20px] text-center text-xl md:hidden"
      />
    </nav>
  );
}
