
import { AgentName } from "../../config/config"
export default function PandaBuySihnUpLink() {

    return (
        <nav  className="flex justify-center text-center">
            <h1 style={{color: 'var(--text-header-color)'}}><a className="underline text-3xl max-sm:text-xl " href="https://www.superbuy.com/en/page/login?partnercode=wUr6VT&type=register"  target='.blank' rel='norefferer' >{AgentName} Signup link $10 off</a></h1>
        </nav>
    )
}
